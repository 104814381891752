<template>
   <v-card outlined tile elevation="2" class="mt-4">
      <v-card-title class="pb-0 ml-4">Pod Assignment</v-card-title>
      
      <pod-description v-if="pod" :name="pod.name" :description="pod.description"></pod-description>
      <v-container style="height: 80px;" v-show="loading">
         <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center indigo--text text--darken-4 font-weight-bold" cols="12">
               Loading Pod Assignments
            </v-col>
            <v-col cols="6">
               <v-progress-linear color="indigo darken-4" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
         </v-row>
      </v-container>
      <v-sheet class="pa-3">
         <v-data-table v-if="pod"
                       :headers="headers"
                       :items="assignments"
                       class="elevation-1"
                       :options.sync="options"
                       :pageCount="pageCount"
                       :sort-by.sync="sortBy"
                       :sort-desc.sync="sortDesc"
                       :server-items-length="assignmentCount"
                       :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-options':rowsPerPage
                        }">
            <template #[`item.actions`]="{ item }">
               <v-icon v-if="displayDelete(item)"  @click="deleteAssignmentDialog(item)">mdi-delete</v-icon>
            </template>
         </v-data-table>
      </v-sheet>
      <v-dialog v-model="deleteDialog" v-if="deleteDialog" persistent max-width="500" content-class="confirm-dialog">
         <v-card>
            <v-card-title class="headline primary">
               Confirm Delete
            </v-card-title>
            <v-card-text>Are you sure you want to delete this Pod Assignment?</v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-card>
</template>

<script>
   import { PodAccessService } from "@/services/PodAccessService";
import { Input } from "@/types/queryInput";
import PodDescription from "../PodDescription";
import { DeletePodAssignments } from "./mutations/delete-pod-assignments.graphql";
import { GetPodAssignments } from "./queries/assignments.graphql";
   
   export default {
      name: "AssignmentList",
      props: {
         podId: Number,
         podAccess: PodAccessService
      },
      components: {
         PodDescription
      },
      data: () => ({
         pod: null,
         user: null,
         loading: true,
         firstLoad: true,
         itemsPerPage: 10,
         skipGetPodMembershipsQuery: true,
         deleteDialog: false,
         itemToDelete: null,
         options: {
            page: 1
         },
         headers: [
            {
               text: "User First Name",
               value: "user.firstName",
               align: "start",
            },
            {
               text: "User Last Name",
               value: "user.lastName"
            },
            {
               text: "Role Description",
               value: "podRole.description"
            },
            {
               text: "Actions",
               value: "actions",
               sortable: false
            }
         ],
         sortBy: "podRole.description",
         sortDesc: false
      }),
      created() {
         this.triggerQuery();
      },
      computed: {
         rowsPerPage() {
            return this.$rowsPerPage;
         },
         sortInput() {
            return {
               column: this.sortBy,
               order:  this.sortDesc ? "desc" : "asc",
            };
         },
         podAssignmentInput() {
            let input = new Input();
            input.take = this.itemsPerPage;
            input.skip = this.itemsPerPage * (this.options.page - 1);
            input.sort = this.sortInput;
            
            return input;
         },
         assignments() {
            return this.pod && this.pod.assignments ? this.pod.assignments.data : [];
         },
         assignmentCount() {
            return this.pod && this.pod.assignments.count ? this.pod.assignments.count : 0;
         },
         pageCount() {
            if(this.assignmentCount === 0) return 0;
            
            return this.itemsPerPage / this.assignmentCount;
         }
      },
      methods: {
         triggerQuery() {
            if (!this.firstLoad) {
               return;
            }
            this.$apollo.queries.pod.skip = false;
            this.$apollo.queries.pod.refetch();
            
            this.firstLoad = false;
         },
         displayDelete(item) {
            return this.podAccess && this.podAccess.displayByUserPodRoleAssignment(item.podRole.podRoleId);
         },
         deleteAssignmentDialog(item) {
            this.deleteDialog = true;
            this.itemToDelete = item;
         },
         async confirmDialogCallback(agreed) {
            if (agreed) {
               await this.deleteItem();
            }
            this.deleteDialog = false;
            this.itemToDelete = null;
         },
         async deleteItem() {
            await this.$apollo.mutate(
                          {
                             mutation: DeletePodAssignments,
                             variables: {
                                input: {
                                   podRoleId: null,
                                   podId: this.podId,
                                   updatedByUserId: this.$store.state.achillesUser.userId,
                                   podAssignmentIds: [this.itemToDelete.podAssignmentId]
                                }
                             }
                          })
                      .then(async (data) => {
                         this.itemToDelete = null;
                         
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         this.itemToDelete = null;
                         
                         await this.failure(error);
                      });
         },
         async success() {
            this.$emit("deleted");
         },
         async failure(data) {
            let errorTest = "Unable to delete pod assignment";
            
            if (typeof data.errorCode !== undefined) {
               errorTest += `: ${data.errorCode}`;
            }
            //exception from API
            else {
               console.log(data);
            }
            
            let options = {
               text: errorTest,
               title: "Unable to delete",
               actions: ["Okay"]
            };
            
            await this.$dialog.error(options);
         }
      },
      apollo: {
         pod: {
            query: GetPodAssignments,
            variables() {
               return {
                  podId: this.podId,
                  podAssignmentInput: this.podAssignmentInput
               };
            },
            update(data) {
               this.loading = false;
               return data.pod;
            },
            // Disable query on component load.
            // We need to execute query when component is created and props are passed 
            skip() {
               return this.skipGetPodMembershipsQuery;
            }
         }
      },
      watch: {
         options: {
            handler() {
               const {
                  sortBy,
                  sortDesc,
                  page,
                  itemsPerPage
               } = this.options;
               
               this.itemsPerPage = itemsPerPage;
               this.sortBy = sortBy[0];
               this.sortDesc = sortDesc[0];
               
               this.triggerQuery();
            },
            deep: true
         }
      }
   };
</script>

<style scoped lang="scss">
   .confirm-dialog .v-card__title {
      color: #FFFFFF;
      font-size: 1.25rem !important;
      line-height: 1.5 !important;
   }
   
   .confirm-dialog .v-card__text {
      margin-top: 26px;
      font-size: 1rem !important;
   }
</style>