<template>
   <v-main>
      <v-container>
         <v-btn @click="goBack" class="mt-4">Back</v-btn>
         
         <v-btn class="float-right primary mt-4 mr-4" v-if="displayAdd" @click="addDialog = true">Assign To
                                                                                                  Pod
         </v-btn>
         
         <!-- Assign To Pod Dialog-->
         <v-dialog v-model="addDialog" max-width="800px" persistent v-if="addDialog">
            <v-toolbar dark color="primary">
               <v-btn icon dark @click="addDialog = false">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-toolbar-title>Assign To Pod</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-toolbar-items>
                  <v-btn dark text @click="onAssignToPodCanceled()">Close</v-btn>
               </v-toolbar-items>
            </v-toolbar>
            <assign-to-pod v-if="addDialog && podId" :podId="podId" :podAccess="podAccess"
                           @canceled="onAssignToPodCanceled()" @saved="onAssignToPodSaved()">
            </assign-to-pod>
         </v-dialog>
         <!-- END: Assign To Pod-->
         
         <!-- Assignment List-->
         <assignment-list v-if="podId" :podId="podId" :podAccess="podAccess" :key="renderList" @deleted="onDeleteAssignmentsSaved()"></assignment-list>
         <!--END:  Assignment List-->
      </v-container>
   </v-main>
</template>

<script>
   import router from "@/router";
   import AssignmentList from "../components/Pods/Assignment/AssignmentList";
   import AssignToPod from "../components/Pods/Assignment/AssignToPod";
   import {Input} from "@/types/queryInput";
   import {PodAccessService} from "@/services/PodAccessService";
   
   export default {
      name: "PodAssignments",
      components: {
         AssignmentList,
         AssignToPod
      },
      data: () => ({
         podId: null,
         renderList: 0,
         addDialog: false,
         podAccessServices: null
      }),
      computed: {
         userRoleInput() {
            return new Input();
         },
         podAccess() {
            if (!this.podAccessServices) {
               return null;
            }
            return this.podAccessServices;
         },
         displayAdd() {
            return this.podAccess && this.podAccess.displayAddPodAssignment();
         }
      },
      methods: {
         goBack() {
            router.back();
         },
         onAssignToPodCanceled() {
            this.addDialog = false;
         },
         onAssignToPodSaved() {
            this.addDialog = false;
            this.clearApolloCache();
            this.renderList += 1;
         },
         onDeleteAssignmentsSaved() {
            this.deleteDialog = false;
            this.clearApolloCache();
            this.renderList += 1;
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
      },
      async created() {
         this.podId = this.$route.params.podId;
         
         let service = new PodAccessService(this.$store.state.achillesUser.userId, this.podId, this.$apollo);
         //get user pod role for action buttons
         await service.getUser()
                      .then(() => {
                         this.podAccessServices = service;
                         this.podAccessServices.setIsSuperAdmin();
                      });
         
      }
   };
</script>

<style scoped lang="scss">

</style>