<template>
   <v-card min-height="400">
      <v-container class="pt-0 assignment-container">
         <v-card-text class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation class="assignment-form">
               <v-row>
                  <v-col col="6">
                     <v-select ref="customers" :items="podRolesFilteredByUserPodRole" label="Pod Roles" item-text="description" item-value="podRoleId" :rules="podRoleRule"
                               required v-model="selectedPodRoleId" @change="getPodRoleUsers" class="ml-4"></v-select>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col col="6">
                     <v-autocomplete ref="users" v-if="users" v-model="selectedUsers"
                                     :items="users" :loading="loading"
                                     :search-input.sync="search" item-value="userId"
                                     :return-object="true" dense no-filter multiple chips
                                     label="Users" item-text="Users" class="ml-4"
                                     @change="clearSearch()">
                        <template v-slot:selection="data">
                           <v-chip v-bind="data.attrs" :input-value="data.selected.userId" :key="data.item.userId" close @click="data.select"
                                   @click:close="removeSelectedUser(data.item.userId)">
                              <v-avatar left>
                                 <v-img :src="data.item.avatarURL" lazy-src="../../../assets/account.png"></v-img>
                              </v-avatar>
                              {{data.item.lastName + ' ' + data.item.firstName}}
                           </v-chip>
                        </template>
                        <template v-slot:item="data">
                           <v-list-item-avatar v-if="data.item.avatarURL != null">
                              <v-img lazy-src="../../../assets/account.png"/>
                           </v-list-item-avatar>
                           <v-list-item-avatar v-if="data.item.avatarURL == null">
                              <v-img src="../../../assets/account.png" lazy-src="../../../assets/account.png"/>
                           </v-list-item-avatar>
                           <v-list-item-content>
                              <v-list-item-title v-html="data.item.email"></v-list-item-title>
                              <v-list-item-subtitle v-html="data.item.lastName + ' ' + data.item.firstName"></v-list-item-subtitle>
                           </v-list-item-content>
                        </template>
                        <template v-slot:append-item>
                           <div v-intersect="endIntersect"/>
                        </template>
                     </v-autocomplete>
                  </v-col>
               </v-row>
               <v-container style="height: 80px;" v-show="isInProgress">
                  <v-row class="fill-height" align-content="center" justify="center">
                     <v-col class="text-subtitle-1 text-center indigo--text text--darken-4 font-weight-bold" cols="12">
                        Assigning to Pod
                     </v-col>
                     <v-col cols="6">
                        <v-progress-linear color="indigo darken-4" indeterminate rounded height="6"></v-progress-linear>
                     </v-col>
                  </v-row>
               </v-container>
            </v-form>
         </v-card-text>
         
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onCancel">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="onReset">Reset</v-btn>
            <v-btn color="blue darken-1" text @click="onSave">Assign to Pod</v-btn>
         </v-card-actions>
      
      </v-container>
   </v-card>
</template>

<script>
   import {
      GetPodRoles,
      GetUsersByPodRole
   } from "./queries/assign-to-pod.graphql";
   import {AddPodAssignments} from "./mutations/assign-to-pod.graphql";
   import {Input} from "@/types/queryInput";
   import {PodAccessService} from "@/services/PodAccessService";
   
   export default {
      name: "AssignToPod",
      props: {
         podId: Number,
         podAccess: PodAccessService
      },
      created() {
         this.triggerPodRoleQuery();
      },
      data: () => ({
         initialLoad: true,
         isInProgress: false,
         skipGetPodRoles: true,
         skipUsers: true,
         podRoles: [],
         users: [],
         allLoadedUsers: [],
         count: 0,
         page: 1,
         itemsPerPage: 10,
         search: null,
         searchWord: "",
         loading: false,
         selectedPodRoleId: null,
         selectedUsers: [],
         assignments: [],
         valid: false,
         podRoleRule: [
            v => !!v || "Pod Role is required"
         ],
         usersRule: [
            v => !!v || "Users selection is required"
         ]
      }),
      computed: {
         userInput() {
            let input = new Input();
            input.take = this.itemsPerPage;
            input.skip = this.itemsPerPage * (this.page - 1);
            input.searchWord = this.searchWord;
            
            return input;
         },
         userCount() {
            return this.count ? this.count : 10;
         },
         pageCount() {
            return Math.ceil(this.userCount / this.itemsPerPage);
         },
         podRolesFilteredByUserPodRole() {
            if (!this.podAccess || !this.podRoles) {
               return [];
            }
            let that = this;
            let podRoles = this.podRoles.filter((item) => {
               return that.podAccess.displayByUserPodRoleAssignment(item.podRoleId);
            });
            
            return podRoles;
         }
      },
      methods: {
         triggerPodRoleQuery() {
            if (!this.initialLoad) {
               return;
            }
            this.$apollo.queries.podRoles.skip = false;
            this.$apollo.queries.podRoles.refetch();
            this.initialLoad = false;
         },
         getPodRoleUsers() {
            this.resetUsers();
            this.getUsers();
         },
         getUsers() {
            if (this.selectedPodRoleId == null) {
               return;
            }
            this.loading = true;
            this.$apollo.queries.users.skip = false;
            this.$apollo.queries.users.refetch();
         },
         onCancel() {
            this.$emit("canceled");
         },
         onReset() {
            this.resetPodRoles();
            this.resetUsers();
         },
         resetUsers() {
            this.searchWord = "";
            this.selectedUsers = [];
            this.allLoadedUsers = [];
            this.users = [];
            this.page = 1;
            this.$apollo.queries.users.skip = true;
         },
         resetPodRoles() {
            this.selectedPodRoleId = null;
         },
         removeSelectedUser(userId) {
            let idx = null;
            this.selectedUsers.findIndex((element, index) => {
               if (element.userId === userId) {
                  idx = index;
               }
            });
            if (idx == null) {
               return;
            }
            this.selectedUsers.splice(idx, 1);
            this.selectedUsers = [...this.selectedUsers];
         },
         changed() {
            this.$emit("changed", this.selectedUsers);
         },
         async success() {
            this.clearApolloCache();
            this.isInProgress = false;
            
            let options = {
               text: "Pod assignments added successfully",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
            this.$emit("saved");
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
         async failure(data) {
            this.isInProgress = false
            let errorTest = "Unable to add pod assignment";
            
            if (typeof data.errorCode !== undefined) {
               errorTest += `: ${data.errorCode}`;
            }
            //exception from API
            else {
               console.log(data);
            }
            
            let options = {
               text: errorTest,
               title: "Unable to save",
               actions: ["Okay"]
            };
            
            this.selectedUsers = [];
            await this.$dialog.error(options);
         },
         async onSave() {
            if (!this.$refs.form.validate()) {
               let options = {
                  text: "Please fix the highlighted issues before savings",
                  title: "Unable to save",
                  actions: ["Okay"]
               };
               await this.$dialog.error(options);
               return;
            }
            this.isInProgress = true;
            
            this.setMutationInput();
            await this.addAssignments();
         },
         setMutationInput() {
            let that = this;
            this.selectedUsers.forEach((user) => {
               let assignment = {
                  userId: user.userId,
                  podId: that.podId,
                  createdByUserId: that.$store.state.achillesUser.userId,
                  podRoleId: that.selectedPodRoleId
               };
               that.assignments.push(assignment);
            });
         },
         async addAssignments() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: AddPodAssignments,
                             variables: {
                                input: {
                                   assignments: this.assignments
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         endIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
               this.page += 1;
               this.allLoadedUsers = this.allLoadedUsers.concat(this.users);
               this.getUsers();
            }
         },
         clearSearch(){
            this.search = '';
            this.searchWord = "";
            this.allLoadedUsers = [];
            this.getUsers();
         }
      },
      apollo: {
         podRoles: {
            query: GetPodRoles,
            variables() {
               return {
                  podId: this.podId
               };
            },
            update(data) {
               return data.pod.podRoles.data.sort(
                   (a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1);
            },
            // Disable query on component load.
            // We need to execute query when component is created and props are passed 
            skip() {
               return this.skipGetPodRoles;
            }
         },
         users: {
            query: GetUsersByPodRole,
            variables() {
               return {
                  userInput: this.userInput,
                  podRoleId: this.selectedPodRoleId,
                  podId: this.podId
               };
            },
            update(data) {
               this.count = data.pod.podRole.users.count;
               let sortedUsers = data.pod.podRole.users.data.sort(
                   (a, b) => a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1);
               
               return sortedUsers;
            },
            async result(data) {
               
               if (this.page > 1) {
                  this.users = this.allLoadedUsers.concat(this.users);
               }
               if (this.selectedUsers.length > 0) {
                  this.users = this.users.concat(this.selectedUsers);
               }
               
               this.loading = data.loading;
               
            },
            skip() {
               return this.skipUsers;
            }
         }
      },
      watch: {
         search(val) {
            if (val && val.length >= 3 && this.searchWord !== val) {
               this.searchWord = val;
               this.allLoadedUsers = [];
               this.page = 1;
               this.getUsers();
               return;
            }
            if (!val || (val.length === 0 && this.searchWord !== val)){
               this.searchWord = "";
               this.allLoadedUsers = [];
               this.page = 1;
               this.getUsers();
            }
         }
      }
   };
</script>

<style scoped lang="scss">
   .container {
      &.assignment-container {
         min-height: inherit;
         
         .assignment-form {
            height: 330px
         }
      }
   }
</style>